<script>
import { SuawMainContent, SuawParagraph, SuawHeading } from "@suaw/suaw-component-library";
import ArticleFrame from "@/features/articles/components/ArticleFrame.vue";

export default {
  name: "BeginningTipsForWritersTheBasics",
  components: {
    SuawMainContent,
    SuawParagraph,
    SuawHeading,
    ArticleFrame
  }
};
</script>

<template>
  <SuawMainContent size="large">
    <ArticleFrame article-id="BeginningTipsForWritersTheBasics">
      <SuawParagraph
        text="<em>I wrote this series a long time ago to share what I’ve learned. Since I’ve been writing regularly for quite a few years, I thought this would be fun to update. None of this advice is cut in stone and what works for me may not work for you.</em>"
      />
      <SuawHeading level="3" content="The Basics" />
      <SuawParagraph text="Write all the time." />
      <SuawParagraph text="Read all the time." />
      <SuawParagraph text="Never give up." />
      <SuawParagraph
        text="Those are the three classic pieces of writing advice and they are probably the most important. You’re never going to get anywhere unless you write. You’re never going to learn anything unless you read. And you can’t ever give up."
      />
      <SuawParagraph
        text="One reason this advice is so important is it takes a long time to grow into a writer. When you first start, your writing is going to be different two, five, and 11 years later. I read somewhere that you don’t really grow into a writer until about 10 years after you’ve started. Of course, there are exceptions. Some people are brilliant from the get-go and progress quickly, but you won’t know if you fall into that category until you get started."
      />
      <SuawParagraph
        text="Writing all the time is the bedrock of the writing life. Easier said than done. There’s work, family, and a million other things going on in your life. Making the time still has to be a priority. If you’re just starting out or trying to get re-started, I recommend you try carving out two hours a week. It may not seem like a lot but this is where I started and it becomes easier to build on. If two hours a week is too much then start with one hour a week. Where can you find this time? If you watch television is there a one or two-hour block you can give up. That’s probably the easiest place to start."
      />
      <SuawHeading level="3" content="Endurance or Writing All The Time" />
      <SuawParagraph
        text="One thing I’ve learned, and this goes along with writing all the time, is the concept of endurance. Before I joined Shut Up &amp; Write! I was flailing around. I had a tough time sitting down and focusing. I didn’t have the necessary mental endurance built up to write regularly."
      />
      <SuawParagraph
        text="Getting to that place where you can write can be difficult. Don’t give up. It takes time to train your brain to sit and focus on your work. For most people, this does not come naturally and can even feel uncomfortable at first. Build that endurance, keep at it."
      />
      <SuawParagraph
        text="In our Shut Up &amp; Write sessions we get together at a cafe for an hour and write. Since going to these sessions regularly I’ve found my endurance for writing has increased dramatically. Because I’ve built up this foundation and practice, I can write for short periods of time and still be productive. Many times I’ll start a scene for a few minutes during my lunch break. I write it down on 4×6 index cards then type it up when I get home or I write on my iPad. This keeps me connected to the story and helps me feel productive because I’ve been doing some writing throughout the day."
      />
      <SuawParagraph
        text="If Shut Up &amp; Write isn’t in your city yet, we can help you start one — just <a target='_blank' title='mailto:questions@suandw.com' href='mailto:questions@suandw.com'>contact our HQ team</a>. Writing with other people, even for an hour, is invaluable."
      />
      <SuawHeading level="3" content="Read All The Time" />
      <SuawParagraph
        text="Admittedly, I need to do more of this. The idea is that you learn to write by reading widely. Not only in your chosen genre, which is very important, but also in completely different areas as well. Reading brilliant works is a no-brainer but there’s also much to be learned from reading bad books. Usually when I find a scene or dialog or description that stands out for me, either good or bad, I’ll ask “Why does this work?” or “Why is this so terrible?” and take some notes. Reading this way is different than reading for pleasure, you pay attention to the plot, characters, the story world, and style of writing to learn more about how books work."
      />
      <SuawParagraph
        text="I also recommend reading poetry and graphic novels/comics/manga on a regular basis if you don’t usually read them. Reading poetry will give you a different perspective on language and how to use it. Graphic novels/comics/manga tell stories in a very different way using pictures with words. This will also broaden your perspective and give you some new ideas."
      />
      <SuawHeading level="3" content="Never Give Up" />
      <SuawParagraph
        text="No matter what happens, keep going. If the project doesn’t work, you start the next one. All this means is you have more to learn. If you have to stop for a period of time because life gets in the way, work your way back to your project when you can come up for air."
      />
      <SuawParagraph
        text="I used to envy people who had to write; it’s a compulsive need they have. Stephen King is a famous example of such a person. I didn’t have that need when I started out but now I’ve turned into the person who must write, no matter what. This was something that happened to me over a period of time of regular practice and learning about my own working style."
      />
      <SuawParagraph
        text="I know now that I will never stop writing because the process is so satisfying. Writing is like climbing a mountain, sometimes all you see is the trail in front of you. In this way, it feels like you’re struggling to find your way. Many times you want to stop but you keep going. We get so close to our words that when we finally make it to the top, we see what we’ve created and there’s a kind of awe that happens: I wrote a story and it WORKS!"
      />
      <SuawParagraph text="Writing is all about experimentation with words and with techniques. We’d love to hear about writing techniques that have worked for you." />
    </ArticleFrame>
  </SuawMainContent>
</template>
